<template>
  <van-nav-bar
    :title="title"
    right-text="创建"
    @click-right="onOpenForm(null)"
  />
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 15px 0;">
          <small>{{ item.name }}</small>
        </p>
        <van-button @click="onOpenForm(item)">修改</van-button>
      </div>
    </template>
  </van-list>
  <van-popup v-model:show="form.show" position="bottom">
    <van-form @submit="onSubmit">
      <van-field
        v-model="form.row.name"
        name="name"
        label="名称"
        placeholder=""
      />
      <van-field
        v-model="form.row.sort"
        type="digit"
        name="sort"
        label="排序"
        placeholder="0-255之间，数字越大显示越靠前"
      />
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">提交</van-button>
        <template v-if="(typeof form.row.tagId !== 'undefined')">
          <van-button round block style="margin:15px 0 0 0;" @click="onDelete(form.row.tagId)">删除</van-button>
        </template>
      </div>
    </van-form>
  </van-popup>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      form: {
        show: false,
        row: {}
      },
      row: {
      },
      tags: [],
      storeId: Cookies.get('storeId')
    })
    const onOpenForm = (item) => {
      state.form.show = true
      if (item !== null) {
        // state.form.row = item
        state.form.row = Object.assign({}, item)
      } else {
        state.form.row = {}
      }
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const onSubmit = (values) => {
      console.log(values)
      if (typeof state.form.row.tagId === 'undefined') {
        post('/tag.create', {
          ...values,
          storeId: state.storeId
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      } else {
        post('/tag.update', {
          ...values,
          tagId: state.form.row.tagId
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const onDelete = (id) => {
      Dialog.confirm({
        title: '提示',
        message: '操作确认'
      }).then(() => {
        console.log(id)
        post('/tag.delete', {
          tagIds: id.toString().split(',')
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {
      })
    }
    const getData = () => {
      post('/tag.list', {
        pageNum: state.page,
        storeId: state.storeId
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onOpenForm,
      onSubmit,
      onDelete
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
